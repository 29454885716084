import * as THREE from "three";

import ProjectBoardMaterial from "../../Materials/ProjectBoard.js";
import TweenLite from "gsap/TweenLite";
import { Power4 } from "gsap/EasePack";
import { CSS3DObject } from "three/examples/jsm/renderers/CSS3DRenderer.js";

export default class Project {
    constructor(_options) {
        // Options
        this.time = _options.time;
        this.resources = _options.resources;
        this.objects = _options.objects;
        this.areas = _options.areas;
        this.name = _options.name;
        this.geometries = _options.geometries;
        this.meshes = _options.meshes;
        this.debug = _options.debug;
        this.name = _options.name;
        this.x = _options.x;
        this.y = _options.y;
        this.imageSources = _options.imageSources;
        this.floorTexture = _options.floorTexture;
        this.floorHTML = _options.floorHTML; // * new floorHTML
        this.link = _options.link;
        this.distinctions = _options.distinctions;

        // Set up
        this.container = new THREE.Object3D();
        this.container.matrixAutoUpdate = false;
        // this.container.updateMatrix()

        this.setBoards();
        this.setFloor();
    }

    setBoards() {
        // Set up
        this.boards = {};
        this.boards.items = [];
        this.boards.xStart = -5;
        this.boards.xInter = 5;
        this.boards.y = 3;
        this.boards.color = "#8e7161";
        this.boards.threeColor = new THREE.Color(this.boards.color);

        if (this.debug) {
            this.debug
                .addColor(this.boards, "color")
                .name("boardColor")
                .onChange(() => {
                    this.boards.threeColor.set(this.boards.color);
                });
        }

        // Create each board
        let i = 0;
        const tempRotationBoard = -Math.PI / 4; // * Temp rotation
        const tempRotationImage = -Math.PI / 8 + 0.04; // * Temp rotation

        for (const _imageSource of this.imageSources) {
            // Set up
            const board = {};
            board.x = this.x + this.boards.xStart + i * this.boards.xInter;
            board.y = this.y + this.boards.y;

            // Create structure with collision
            this.objects.add({
                base: this.resources.items.projectsBoardStructure.scene,
                collision: this.resources.items.projectsBoardCollision.scene,
                floorShadowTexture:
                    this.resources.items
                        .projectsBoardStructureFloorShadowTexture,
                offset: new THREE.Vector3(board.x, board.y, 0),
                rotation: new THREE.Euler(0, 0, tempRotationBoard), // * Temp rotation
                duplicated: true,
                mass: 0,
            });

            // Image load
            const image = new Image();
            image.addEventListener("load", () => {
                board.texture = new THREE.Texture(image);
                board.texture.needsUpdate = true;
                board.texture.magFilter = THREE.NearestFilter;
                board.texture.minFilter = THREE.LinearFilter;

                board.planeMesh.material.uniforms.uTexture.value =
                    board.texture;

                TweenLite.to(
                    board.planeMesh.material.uniforms.uTextureAlpha,
                    1,
                    { value: 1, ease: Power4.inOut }
                );
            });

            image.src = _imageSource;

            // Plane
            board.planeMesh = this.meshes.boardPlane.clone();
            board.planeMesh.position.x = board.x;
            board.planeMesh.position.y = board.y;
            board.planeMesh.matrixAutoUpdate = false;
            board.planeMesh.rotation.z = tempRotationImage; // * Temp rotation
            board.planeMesh.updateMatrix();
            board.planeMesh.material = new ProjectBoardMaterial();
            board.planeMesh.material.uniforms.uColor.value =
                this.boards.threeColor;
            board.planeMesh.material.uniforms.uTextureAlpha.value = 0;
            this.container.add(board.planeMesh);

            // Save
            this.boards.items.push(board);

            i++;
        }
    }

    // // * New setHtml method
    // setHtml() {
    //     this.html = {};

    //     // Create the HTML element
    //     this.html.element = document.createElement("div");
    //     this.html.element.classList.add("custom-css-class");

    //     // Apply custom styles
    //     this.html.element.style.opacity = "0"; // Start with the element being transparent
    //     this.html.element.style.transition = "opacity 0.3s ease-in-out"; // Fade-in transition
    //     this.html.element.style.position = "absolute"; // Position absolutely
    //     this.html.element.style.maxWidth = "50px"; // Limit width for better UI
    //     this.html.element.style.padding = "10px"; // Add padding for spacing
    //     this.html.element.style.overflow = "hidden"; // Prevent overflow content

    //     // Set the HTML content
    //     this.html.element.innerHTML = this.floorHTML;

    //     // Create CSS3DObject
    //     this.css3DObject = new CSS3DObject(this.html.element);
    //     this.css3DObject.position.set(this.x, this.y + 20, 0); // Adjust position
    //     this.css3DObject.scale.set(0.018, 0.018, 0.018); // Adjust scale to fit the scene
    //     this.css3DObject.rotation.set(0, 0, 0); // Adjust rotation if needed

    //     // Add the CSS3DObject to the project container
    //     this.container.add(this.css3DObject);

    //     // Animate fade-in effect once the element is added
    //     // setTimeout(() => {
    //     //     this.html.element.style.opacity = "1";
    //     // }, 100); // Delay fade-in for smoother UX
    // }

    setFloor() {
        this.floor = {};

        this.floor.x = 0;
        this.floor.y = -2;

        // Container
        this.floor.container = new THREE.Object3D();
        this.floor.container.position.x = this.x + this.floor.x;
        this.floor.container.position.y = this.y + this.floor.y;
        this.floor.container.matrixAutoUpdate = false;
        this.floor.container.updateMatrix();
        this.container.add(this.floor.container);

        // Add HTML content inside the floor container
        if (this.floorHTML) {
            const htmlElement = document.createElement("div");
            htmlElement.classList.add("custom-css-class");

            // Apply styles
            htmlElement.style.opacity = "0"; // Start with the element being transparent
            htmlElement.style.transition = "opacity 0.3s ease-in-out"; // Fade-in transition
            htmlElement.style.position = "absolute"; // Position absolutely
            htmlElement.style.maxWidth = "600px"; // Limit width for better layout // TODO Adjust based on alignment
            htmlElement.style.padding = "10px"; // Add padding for spacing
            htmlElement.style.overflow = "hidden"; // Prevent overflow content

            // Set HTML content
            htmlElement.innerHTML = this.floorHTML;

            // Create CSS3DObject
            const css3DObject = new CSS3DObject(htmlElement);
            css3DObject.position.set(-2.2, 2, 0); // Adjust position relative to the floor // TODO Adjust based on alignment
            css3DObject.scale.set(0.02, 0.02, 0.02); // Adjust scale for the scene
            this.floor.container.add(css3DObject);

            // Fade-in animation
            setTimeout(() => {
                htmlElement.style.opacity = "1";
            }, 100);
        }

        if (this.link) {
            // Area
            this.floor.area = this.areas.add({
                position: new THREE.Vector2(
                    this.x + this.link.x,
                    this.y + this.floor.y + this.link.y
                ),
                halfExtents: new THREE.Vector2(
                    this.link.halfExtents.x,
                    this.link.halfExtents.y
                ),
            });
            this.floor.area.on("interact", () => {
                window.open(this.link.href, "_blank");
            });

            // Area label
            this.floor.areaLabel = this.meshes.areaLabel.clone();
            this.floor.areaLabel.position.x = this.link.x;
            this.floor.areaLabel.position.y = this.link.y;
            this.floor.areaLabel.position.z = 0.001;
            this.floor.areaLabel.matrixAutoUpdate = false;
            this.floor.areaLabel.updateMatrix();
            this.floor.container.add(this.floor.areaLabel);
        }

        // * Removed floorTexture for my projects
        if (!this.floorTexture) return; // Exit early if no floor texture

        if (this.floorTexture) {
            // Texture
            this.floor.texture = this.floorTexture;
            this.floor.texture.magFilter = THREE.NearestFilter;
            this.floor.texture.minFilter = THREE.LinearFilter;

            // Geometry
            this.floor.geometry = this.geometries.floor;

            // Material
            this.floor.material = new THREE.MeshBasicMaterial({
                transparent: true,
                depthWrite: false,
                alphaMap: this.floor.texture,
            });

            // Mesh
            this.floor.mesh = new THREE.Mesh(
                this.floor.geometry,
                this.floor.material
            );
            this.floor.mesh.matrixAutoUpdate = false;
            this.floor.container.add(this.floor.mesh);
        }

        // Distinctions
        if (this.distinctions) {
            for (const _distinction of this.distinctions) {
                let base = null;
                let collision = null;
                let shadowSizeX = null;
                let shadowSizeY = null;

                switch (_distinction.type) {
                    case "awwwards":
                        base =
                            this.resources.items
                                .projectsDistinctionsAwwwardsBase.scene;
                        collision =
                            this.resources.items
                                .projectsDistinctionsAwwwardsCollision.scene;
                        shadowSizeX = 1.5;
                        shadowSizeY = 1.5;
                        break;

                    case "fwa":
                        base =
                            this.resources.items.projectsDistinctionsFWABase
                                .scene;
                        collision =
                            this.resources.items
                                .projectsDistinctionsFWACollision.scene;
                        shadowSizeX = 2;
                        shadowSizeY = 1;
                        break;

                    case "cssda":
                        base =
                            this.resources.items.projectsDistinctionsCSSDABase
                                .scene;
                        collision =
                            this.resources.items
                                .projectsDistinctionsCSSDACollision.scene;
                        shadowSizeX = 1.2;
                        shadowSizeY = 1.2;
                        break;
                }

                this.objects.add({
                    base: base,
                    collision: collision,
                    offset: new THREE.Vector3(
                        this.x + this.floor.x + _distinction.x,
                        this.y + this.floor.y + _distinction.y,
                        0
                    ),
                    rotation: new THREE.Euler(0, 0, 0),
                    duplicated: true,
                    shadow: {
                        sizeX: shadowSizeX,
                        sizeY: shadowSizeY,
                        offsetZ: -0.1,
                        alpha: 0.5,
                    },
                    mass: 1.5,
                    soundName: "woodHit",
                });
            }
        }

        // * MOVED UP
        // // Area
        // this.floor.area = this.areas.add({
        //     position: new THREE.Vector2(
        //         this.x + this.link.x,
        //         this.y + this.floor.y + this.link.y
        //     ),
        //     halfExtents: new THREE.Vector2(
        //         this.link.halfExtents.x,
        //         this.link.halfExtents.y
        //     ),
        // });
        // this.floor.area.on("interact", () => {
        //     window.open(this.link.href, "_blank");
        // });

        // // Area label
        // this.floor.areaLabel = this.meshes.areaLabel.clone();
        // this.floor.areaLabel.position.x = this.link.x;
        // this.floor.areaLabel.position.y = this.link.y;
        // this.floor.areaLabel.position.z = 0.001;
        // this.floor.areaLabel.matrixAutoUpdate = false;
        // this.floor.areaLabel.updateMatrix();
        // this.floor.container.add(this.floor.areaLabel);
    }
}
